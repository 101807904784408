.success_background {
    background-color: rgb(84, 133, 87);
}
.error_background {
    background-color: #cf4242;
}

.utilLightErrorNotification {
    width:100%;
    color:white;
    position:fixed;
    text-align:center;
    top:0%;
    left:0%;
    z-index: 1040;
    overflow: hidden;
    transition: height 1s;
    height:0px;
}
.utilLightErrorNotification_visible  {
    height:40px;/*La fuente debe ser menor a este tamaño*/
}